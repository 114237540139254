import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faHome, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { TranslatorProvider, useTranslate } from "react-translate";
import './App.css';


let translationsEN = {
  locale: "en",
  Login: {
    "Username": "User name"
  }
};

let translationsPL = {
  locale: "pl",
  Login: {
    Welcome: "Dom Kowalskich",
    EnterEmail: "podaj swój email",
    EnterPassword: "podaj hasło",
    ForgotPassword: "Nie pamiętam hasła",
    LoginWithEmail: "Zaloguj się",
    LoginWithGoogle: "Zaloguj się używając Google",
    LoginWithFacebook: "Zaloguj się używając Facebook",
    LoginWithTwitter: "Zaloguj się używając Twitter",
    Username: "Nazwa użytkownika"
  },
  LoggedIn: {
    loggedInAs: "Użytkownik:",
    Logout: "Wyloguj się",
  },
};

function Login() {
  const t = useTranslate("Login");
  const usp = new URLSearchParams(window.location.search);

  const googleLoginUrl = "/auth/google-login?" + new URLSearchParams({
    'redirectTo': usp.get('redirectTo'),
  }).toString();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function loginClicked() {
    axios.post()
  }

  return <div className="App">
    <Container>
      <Form className="login-form" onSubmit={loginClicked}>
        <div className="logo text-primary">
          <FontAwesomeIcon icon={faHome} size="6x" />
        </div>
        <div className="welcome">
          {t("Welcome")}
        </div>
        {/* <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="addon-email"><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder={t("EnterEmail")}
            aria-label="Email"
            aria-describedby="addon-email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="addon-password"><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
          </InputGroup.Prepend>

          <FormControl
            placeholder={t("EnterPassword")}
            aria-label="Password"
            aria-describedby="addon-password"
            value={password}
            type="password"
            onChange={e => setPassword(e.target.value)}
          />
        </InputGroup>

        <Button variant="success" type="submit">
          <FontAwesomeIcon icon={faEnvelope} />&nbsp;{t("LoginWithEmail")}
        </Button>
      &nbsp;
      <Button variant="danger"><FontAwesomeIcon icon={faEnvelope} />&nbsp;{t("ForgotPassword")}</Button>
       */}
        <hr />
        <Button variant="outline-primary" href={googleLoginUrl}><FontAwesomeIcon icon={faGoogle} />&nbsp;{t("LoginWithGoogle")}</Button>
      </Form>
    </Container>
  </div>;
}

function LoggedIn(props) {
  const t = useTranslate("LoggedIn");

  function logoutClicked() {
    axios.post("/auth/logout").then(v => {
      alert('logout!');
    }).catch(e => {
      alert(e.message);
    })
  }

  return <div className="App">
    <Form className="login-form" onSubmit={logoutClicked}>
      <p>
        {t("loggedInAs")} {props.email}
      </p>

      <Button variant="danger" type="submit">
        <FontAwesomeIcon icon={faEnvelope} />&nbsp;{t("Logout")}
      </Button>
    </Form>
  </div>;
}

function App() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    axios.get("/auth/cookie-info").then(v => {
      setLoggedIn(v.data.email);
      setLoading(false);
    }).catch(e => {
      setError(e.message);
      setLoading(false);
    })
  }, []);

  return (
    <TranslatorProvider translations={translationsPL}>
      {error}
      {!loading && (loggedIn ? <LoggedIn email={loggedIn} /> : <Login />)}
    </TranslatorProvider>
  );
}

export default App;
